import type { AddressData, LocationData } from '../common/sign-up/steps/location-info-data';
import { Country } from '../common/enums/country';
import { State as UsState } from '../common/enums/state';
import type { BusinessDetailsInfo, BusinessInfoData } from '../common/sign-up/steps/business-info-data';
import type { PersonalInfo } from '../common/sign-up/steps/personal-info';
import { AccountRequirement } from '../common/enums/account-requirement';
import type { BusinessInfoRequest, BusinessInfoResponse } from '../common/sign-up/store/requests/business-info-request';
import type {
  BusinessIndustryDto,
  BusinessDetailsResponse,
  BusinessClassificationDto,
  BusinessTypeDto,
} from '../common/sign-up/store/requests/business-details-request';
import type { BusinessDetailsData } from '../common/sign-up/store/business-details-data';
import type { BusinessType, BusinessTypes } from '../common/sign-up/common/business-type';
import type { BusinessIndustry } from '../common/sign-up/common/business-industry';
import type { BusinessClassification } from '../common/sign-up/common/business-classification';
import type {
  AccountEmailAndPasswordData,
  AccountFullNameData,
  AccountInfoData,
} from '../common/sign-up/steps/account-info-data';
import type { IdentityVerificationData } from '../common/sign-up/identity-verification-data';
import type { ControllerFullNameInfo, ControllerInfoData } from '../common/sign-up/store/controller-info-data';
import type {
  CompleteControllerInfoRequest,
  ControllerInfoRequest,
  ControllerInfoResponse,
} from '../common/sign-up/store/requests/controller-info-request';
import type {
  BeneficialOwnerDocumentInfoResponse,
  BeneficialOwnersInfoRequest,
  BeneficialOwnersInfoResponse,
  CompleteBeneficialOwnersInfoRequest,
  SaveBeneficialOwnerRequest,
} from '../common/sign-up/store/requests/beneficial-owners-info-request';
import type {
  BeneficialOwnerDocumentInfoData,
  BeneficialOwnerInfo,
  BeneficialOwnersInfoData,
} from '../common/sign-up/store/beneficial-owners-info-data';
import type {
  PaperCheckBankNumbersInfo,
  PaperCheckInfoData,
  PaperCheckPrintSettingsInfo,
} from '../common/sign-up/store/paper-check-info-data';
import type {
  CompletePaperCheckInfoRequest,
  PaperCheckInfoDto,
  PaperCheckInfoRequest,
} from '../common/sign-up/store/requests/paper-check-info-request';
import { extractDocumentRequirements } from '../utils/extract-requirements';
import type { DocumentInfoData, DocumentTypeInfo } from '../common/sign-up/store/document-info-data';
import type {
  DocumentInfoResponse,
  DocumentTypeInfoResponse,
} from '../common/sign-up/store/requests/document-info-request';
import { DocumentType } from '../common/sign-up/components/document-uploader/utils/enums/document-type.enum';
import type { PersonalIdentityInfoRequest } from '../common/sign-up/store/requests/personal-identity-info.request';
import type { ValidateEmailRequest } from '../common/sign-up/store/requests/validate-email-request';
import type { InfoForRetryPersonalResponse } from '../common/sign-up/store/requests/info-for-retry-personal.response';
import type { InfoForRetryPersonal } from '../common/sign-up/store/info-for-retry-personal';
import type { ExtendedFullNameInfo } from '../common/sign-up/store/base/full-name-info';
import type {
  CompleteRetryPersonalRequest,
  SaveInfoForRetryPersonalRequest,
} from '../common/sign-up/store/requests/info-for-retry-personal.request';
import type { InfoForRetryBusinessSoleProprietorshipResponse } from '../common/sign-up/store/requests/info-for-retry-business-sole-proprietorship.response';
import type { InfoForRetryBusinessSoleProprietorship } from '../common/sign-up/store/info-for-retry-business-sole-proprietorship';
import type {
  CompleteRetryBusinessSoleProprietorshipRequest,
  SaveInfoForRetryBusinessSoleProprietorshipRequest,
} from '../common/sign-up/store/requests/info-for-retry-business-sole-proprietorship.request';
import type { InfoForRetryBusiness } from '../common/sign-up/store/info-for-retry-business';
import type { InfoForRetryBusinessResponse } from '../common/sign-up/store/requests/info-for-retry-business.response';
import type {
  CompleteRetryBusinessAndControllerRequest,
  CompleteRetryBusinessRequest,
  SaveInfoForRetryBusinessRequest,
} from '../common/sign-up/store/requests/info-for-retry-business.request';
import type { AskUsAnythingDto } from '../common/sign-up/store/requests/ask-us-anything-request';
import { NavigationStatus } from '../common/enums/navigation-status';
import type { NavigationPointWithoutStatus } from '../common/sign-up/navigation-point';
import { PATH_SIGN_UP } from '../common/constants/paths';

interface State {
  globalLoading: boolean;
  loading: boolean;
  token: string | null;
  isAgent: boolean;
  isSender: boolean;
  isSignUpAvailable: boolean;
  requirements: AccountRequirement[];
  accountInformation: Partial<AccountInfoData>;
  personalInfo: Partial<PersonalInfo>;
  businessInfo: Partial<BusinessInfoData>;
  businessDetails: Partial<BusinessDetailsData>;
  identityInformation: Partial<IdentityVerificationData>;
  controllerInfo: Partial<ControllerInfoData>;
  beneficialOwnersInfo: Partial<BeneficialOwnersInfoData>;
  paperCheckInfo: Partial<PaperCheckInfoData>;
  documentInfo: Partial<DocumentInfoData>;
  infoForRetryPersonal: Partial<InfoForRetryPersonal>;
  infoForRetryBusinessSoleProprietorship: Partial<InfoForRetryBusinessSoleProprietorship>;
  infoForRetryBusiness: Partial<InfoForRetryBusiness>;
  sidebarPointsStatusMap: Record<string, NavigationStatus>;
  sidebarNavigationPoints: NavigationPointWithoutStatus[];
  sidebarCurrentPoint: number;
}

export const useSignUpStore = defineStore('signUpStore', {
  state: (): State => ({
    globalLoading: false,
    loading: false,
    token: null,
    isAgent: false,
    isSender: false,
    isSignUpAvailable: false,
    requirements: [],
    accountInformation: {},
    personalInfo: {},
    businessInfo: {},
    businessDetails: {},
    identityInformation: {},
    controllerInfo: {},
    beneficialOwnersInfo: {},
    paperCheckInfo: {},
    documentInfo: {},
    infoForRetryPersonal: {},
    infoForRetryBusinessSoleProprietorship: {},
    infoForRetryBusiness: {},
    sidebarPointsStatusMap: {},
    sidebarNavigationPoints: [],
    sidebarCurrentPoint: 0,
  }),
  actions: {
    retrieveEmailAndPassword(): AccountEmailAndPasswordData | null {
      const email: string | null = this.accountInformation.email ?? null;
      const password: string | null = this.accountInformation.password ?? null;

      return email && password ? { email, password } : null;
    },
    retrieveFullName(): AccountFullNameData | null {
      const firstName: string | null = this.accountInformation.firstName ?? null;
      const lastName: string | null = this.accountInformation.lastName ?? null;
      const title: string | null = this.accountInformation.title ?? null;

      return firstName && lastName ? { firstName, lastName, title } : null;
    },
    retrieveBusinessName(): string | null {
      return this.accountInformation.businessName ?? null;
    },
    retrievePhoneNumber(): string | null {
      return this.accountInformation.phoneNumber ?? null;
    },
    retrieveDoB(): Date | null {
      return this.accountInformation.dob ?? null;
    },
    startGlobalLoading(): void {
      this.globalLoading = true;
    },
    endGlobalLoading(): void {
      this.globalLoading = false;
    },
    retrievePersonalAddress(): AddressData | null {
      const address1: string | null = this.personalInfo.address1 ?? null;
      const address2: string | null = this.personalInfo.address2 ?? null;
      const address3: string | null = this.personalInfo.address3 ?? null;

      return address1 ? { address1, address2, address3 } : null;
    },
    retrievePersonalLocation(): LocationData | null {
      const country: Country | null = this.personalInfo.country ?? null;
      const city: string | null = this.personalInfo.city ?? null;
      const state: UsState | null = this.personalInfo.state ?? null;
      const provinceOrRegion: string | null = this.personalInfo.provinceOrRegion ?? null;
      const postalCode: string | null = this.personalInfo.postalCode ?? null;

      return city && postalCode ? { country, city, state, provinceOrRegion, postalCode } : null;
    },
    retrieveBusinessUrl(): string | null {
      return this.businessInfo.url ?? null;
    },
    retrieveBusinessDba(): string | null {
      return this.businessInfo.dba ?? null;
    },
    retrieveBusinessPhone(): string | null {
      return this.businessInfo.phone ?? null;
    },
    retrieveBusinessDetails(): Pick<BusinessDetailsInfo, 'type' | 'classification'> | null {
      const businessType: number | null = this.businessInfo.type ?? null;
      const businessClassification: number | null = this.businessInfo.classification ?? null;

      return businessType && businessClassification
        ? {
            type: businessType,
            classification: businessClassification,
          }
        : null;
    },
    retrieveIsPersonalAddressSameForBusiness(): boolean {
      return this.businessInfo.isPersonalAddressSameForBusiness ?? false;
    },
    retrieveBusinessAddress(): AddressData | null {
      const address1: string | null = this.businessInfo.address1 ?? null;
      const address2: string | null = this.businessInfo.address2 ?? null;
      const address3: string | null = this.businessInfo.address3 ?? null;

      return address1 ? { address1, address2, address3 } : null;
    },
    retrieveBusinessLocation(): LocationData | null {
      const country: Country | null = this.businessInfo.country ?? null;
      const city: string | null = this.businessInfo.city ?? null;
      const state: UsState | null = this.businessInfo.state ?? null;
      const provinceOrRegion: string | null = this.businessInfo.provinceOrRegion ?? null;
      const postalCode: string | null = this.businessInfo.postalCode ?? null;

      return city && postalCode ? { country, city, state, provinceOrRegion, postalCode } : null;
    },
    retrieveIsUserController(): boolean {
      return this.businessInfo.isUserController ?? false;
    },
    retrieveControllerApproved(): boolean {
      return this.businessInfo.isHerebyCertifiedController ?? false;
    },
    retrieveBusinessTypes(): BusinessType[] {
      return this.businessDetails.types ?? [];
    },
    retrieveBusinessClassificationsAndIndustries(): BusinessIndustry[] {
      return this.businessDetails.industries ?? [];
    },
    retrievePersonalSSNLastFour(): string | null {
      return this.personalInfo.ssnLastFour ?? null;
    },
    retrievePersonalSSN(): string | null {
      return this.personalInfo.ssnFull ?? null;
    },
    retrievePersonalEIN(): string | null {
      return this.personalInfo.ein ?? null;
    },
    async fetchBusinessDetails(): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: 'sign-up/business-details',
        });

        const data: BusinessDetailsResponse = response.data;

        this.businessDetails = {
          types: data.types.map((t: BusinessTypeDto): BusinessType => ({ id: t.id, name: t.name })),
          industries: data.industries.map(
            (i: BusinessIndustryDto): BusinessIndustry => ({
              id: i.id,
              name: i.name,
              classifications: i.industries.map(
                (i: BusinessClassificationDto): BusinessClassification => ({ id: i.id, name: i.name }),
              ),
            }),
          ),
        } as BusinessDetailsData;
      } finally {
        this.loading = false;
      }
    },
    async fetchBusinessInfo(): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: 'sign-up/business-info',
        });

        const data: BusinessInfoResponse = response.data;

        this.businessInfo = {
          url: data.websiteUrl,
          dba: data.dba,
          type: data.businessType,
          classification: data.businessClassification,
          industry: data.businessIndustry,
          isPersonalAddressSameForBusiness: data.isAccountAddressSameForBusiness,
          isUserController: data.isAccountCompanyController,
          isHerebyCertifiedController: data.isHerebyCertifiedController,
          address1: data.address?.address1,
          address2: data.address?.address2,
          address3: data.address?.address3,
          country: data.address?.country,
          phone: data.phone,
          city: data.address?.city,
          state: data.address?.state,
          provinceOrRegion: data.address?.provinceOrRegion,
          postalCode: data.address?.postalCode,
        } as BusinessInfoData;
      } finally {
        this.loading = false;
      }
    },
    async validateToken(): Promise<boolean> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        await $snapApi({
          method: 'POST',
          url: `sign-up/validate-token`,
          data: { token: this.token },
        });
      } catch {
        this.isSignUpAvailable = false;

        return false;
      } finally {
        this.loading = false;
      }

      return true;
    },
    async post(url: string, data: unknown, isPut: boolean = false): Promise<boolean> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        await $snapApi({
          method: isPut ? 'PUT' : 'POST',
          url,
          data,
        });
      } catch {
        return false;
      } finally {
        this.loading = false;
      }

      return true;
    },
    async savePersonalAddressAndLocation(): Promise<boolean> {
      return await this.post('sign-up/address-and-location/personal', {
        address1: this.personalInfo.address1,
        address2: this.personalInfo.address2,
        address3: this.personalInfo.address3,
        country: this.personalInfo.country,
        city: this.personalInfo.city,
        state: this.personalInfo.state,
        provinceOrRegion: this.personalInfo.provinceOrRegion,
        postalCode: this.personalInfo.postalCode,
      });
    },
    async saveBusinessWebsite(): Promise<boolean> {
      return await this.post('sign-up/business-info', {
        websiteUrl: this.businessInfo.url,
      } as BusinessInfoRequest);
    },
    async saveBusinessDba(): Promise<boolean> {
      return await this.post('sign-up/business-info', {
        dba: this.businessInfo.dba,
      } as BusinessInfoRequest);
    },
    async saveBusinessPhone(): Promise<boolean> {
      return await this.post('sign-up/business-info', {
        phone: this.businessInfo.phone,
      } as BusinessInfoRequest);
    },
    async saveBusinessDetails(): Promise<boolean> {
      return await this.post('sign-up/business-info', {
        businessType: this.businessInfo.type,
        businessClassification: this.businessInfo.classification,
        businessIndustry: this.businessInfo.industry,
      } as BusinessInfoRequest);
    },
    async saveIsPersonalAddressSameForBusiness(): Promise<boolean> {
      return await this.post('sign-up/business-info', {
        isAccountAddressSameForBusiness: this.businessInfo.isPersonalAddressSameForBusiness,
      } as BusinessInfoRequest);
    },
    async saveBusinessAddressAndLocation(): Promise<boolean> {
      return await this.post('sign-up/business-info', {
        address: {
          address1: this.businessInfo.address1,
          address2: this.businessInfo.address2,
          address3: this.businessInfo.address3,
          country: this.businessInfo.country,
          city: this.businessInfo.city,
          state: this.businessInfo.state,
          provinceOrRegion: this.businessInfo.provinceOrRegion,
          postalCode: this.businessInfo.postalCode,
        },
      } as BusinessInfoRequest);
    },
    async saveIsUserController(): Promise<boolean> {
      return await this.post('sign-up/business-info', {
        isAccountCompanyController: this.businessInfo.isUserController,
      } as BusinessInfoRequest);
    },
    async completeBusinessInfo(): Promise<boolean> {
      return await this.post('sign-up/business-info/complete', {
        ssnLastFour: this.personalInfo.ssnLastFour,
        ssnFull: this.personalInfo.ssnFull,
        ein: this.personalInfo.ein,
      });
    },
    async fetchControllerInfo(): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: 'sign-up/controller-info',
        });

        const data: ControllerInfoResponse = response.data;

        this.controllerInfo = {
          firstName: data.firstName,
          lastName: data.lastName,
          title: data.title,
          dob: data.dob ? new Date(data.dob) : null,
          address1: data.address?.address1,
          address2: data.address?.address2,
          address3: data.address?.address3,
          country: data.address?.country,
          city: data.address?.city,
          state: data.address?.state,
          provinceOrRegion: data.address?.provinceOrRegion,
          postalCode: data.address?.postalCode,
        } as ControllerInfoData;
      } finally {
        this.loading = false;
      }
    },
    retrieveControllerFullName(): ControllerFullNameInfo | null {
      const title: string | null = this.controllerInfo.title ?? null;
      const firstName: string | null = this.controllerInfo.firstName ?? null;
      const lastName: string | null = this.controllerInfo.lastName ?? null;

      return title || firstName || lastName ? { title, firstName, lastName } : null;
    },
    async saveControllerFullNameInfo(): Promise<boolean> {
      return await this.post('sign-up/controller-info', {
        firstName: this.controllerInfo.firstName,
        lastName: this.controllerInfo.lastName,
        title: this.controllerInfo.title,
      } as ControllerInfoRequest);
    },
    retrieveControllerDoB(): Date | null {
      return this.controllerInfo.dob ?? null;
    },
    async saveControllerDoBInfo(): Promise<boolean> {
      return await this.post('sign-up/controller-info', {
        dob: this.controllerInfo.dob,
      } as ControllerInfoRequest);
    },
    retrieveControllerAddress(): AddressData | null {
      const address1: string | null = this.controllerInfo.address1 ?? null;
      const address2: string | null = this.controllerInfo.address2 ?? null;
      const address3: string | null = this.controllerInfo.address3 ?? null;

      return address1 ? { address1, address2, address3 } : null;
    },
    retrieveControllerLocation(): LocationData | null {
      const country: Country | null = this.controllerInfo.country ?? null;
      const city: string | null = this.controllerInfo.city ?? null;
      const state: UsState | null = this.controllerInfo.state ?? null;
      const provinceOrRegion: string | null = this.controllerInfo.provinceOrRegion ?? null;
      const postalCode: string | null = this.controllerInfo.postalCode ?? null;

      return city && postalCode ? { country, city, state, provinceOrRegion, postalCode } : null;
    },
    async saveControllerAddressAndLocation(): Promise<boolean> {
      return await this.post('sign-up/controller-info', {
        address: {
          address1: this.controllerInfo.address1,
          address2: this.controllerInfo.address2,
          address3: this.controllerInfo.address3,
          country: this.controllerInfo.country,
          city: this.controllerInfo.city,
          state: this.controllerInfo.state,
          provinceOrRegion: this.controllerInfo.provinceOrRegion,
          postalCode: this.controllerInfo.postalCode,
        },
      } as ControllerInfoRequest);
    },
    retrieveControllerSsn(): string | null {
      return (this.businessInfo.isUserController ? this.personalInfo.ssnFull : this.controllerInfo.ssn) ?? null;
    },
    retrieveControllerPassportNumber(): string | null {
      return this.controllerInfo.passportNumber ?? null;
    },
    retrieveControllerPassportCountry(): Country | null {
      return this.controllerInfo.passportCountry ?? null;
    },
    async completeControllerInfo(): Promise<boolean> {
      return await this.post('sign-up/controller-info/complete', {
        ssn: this.controllerInfo.ssn,
        passportNumber: this.controllerInfo.passportNumber,
        passportCountry: this.controllerInfo.passportCountry,
        isHerebyCertifiedController: this.controllerInfo.isHerebyCertifiedController,
        ssnLastFour: this.personalInfo.ssnLastFour,
        ssnFull: this.personalInfo.ssnFull,
        ein: this.personalInfo.ein,
      } as CompleteControllerInfoRequest);
    },
    async fetchBeneficialOwnersInfo(): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: 'sign-up/beneficial-owners-info',
        });

        const data: BeneficialOwnersInfoResponse = response.data;

        this.beneficialOwnersInfo = {
          isBeneficialOwnersExists: data.isBeneficialOwnersExists,
          isCompleteAvailable: data.isCompleteAvailable,
          isHerebyCertifiedNoBO: data.isHerebyCertifiedNoBO,
          beneficialOwners: data.beneficialOwners.map((bo) => {
            return {
              ...bo,
              isDocumentRequired: bo.isDocumentRequired,
              documentStatus: bo.documentStatus,
              isRetryRequired: bo.isRetryRequired,
              dob: new Date(bo.dob),
              phone: bo.phone,
              address1: bo.address.address1,
              address2: bo.address.address2,
              address3: bo.address.address3,
              country: bo.address.country,
              city: bo.address.city,
              state: bo.address.state,
              provinceOrRegion: bo.address.provinceOrRegion,
              postalCode: bo.address.postalCode,
            } as BeneficialOwnerInfo;
          }),
        };
      } finally {
        this.loading = false;
      }
    },
    retrieveIsBeneficialOwnersExists(): boolean {
      return this.beneficialOwnersInfo.isBeneficialOwnersExists ?? false;
    },
    retrieveIsHerebyCertifiedNoBO(): boolean {
      return this.beneficialOwnersInfo.isHerebyCertifiedNoBO ?? false;
    },
    async saveIsBeneficialOwnersExists(): Promise<boolean> {
      return await this.post('sign-up/beneficial-owners-info', {
        isBeneficialOwnersExists: this.beneficialOwnersInfo.isBeneficialOwnersExists,
      } as BeneficialOwnersInfoRequest);
    },
    retrieveBeneficialOwners(): BeneficialOwnerInfo[] {
      return this.beneficialOwnersInfo.beneficialOwners ?? [];
    },
    retrieveBeneficialOwnerDocumentInfo(): BeneficialOwnerDocumentInfoData | null {
      return this.beneficialOwnersInfo.beneficialOwnerDocumentInfo ?? null;
    },
    retrieveBeneficialOwnerIsCompleteAvailable(): boolean {
      return this.beneficialOwnersInfo.isCompleteAvailable ?? false;
    },
    async saveNewBeneficialOwner(model: BeneficialOwnerInfo): Promise<boolean> {
      return await this.post('sign-up/beneficial-owners', {
        firstName: model.firstName,
        lastName: model.lastName,
        dob: model.dob,
        phone: model.phone,
        ssn: model.ssn ?? null,
        passportNumber: model.passportNumber ?? null,
        passportCountry: model.passportCountry ?? null,
        address: {
          address1: model.address1,
          address2: model.address2,
          address3: model.address3,
          country: model.country,
          city: model.city,
          state: model.state,
          provinceOrRegion: model.provinceOrRegion,
          postalCode: model.postalCode,
        },
      } as SaveBeneficialOwnerRequest);
    },
    async saveBeneficialOwner(id: number, model: BeneficialOwnerInfo): Promise<boolean> {
      return await this.post(
        `sign-up/beneficial-owners/${id}`,
        {
          firstName: model.firstName,
          lastName: model.lastName,
          dob: model.dob,
          phone: model.phone,
          address: {
            address1: model.address1,
            address2: model.address2,
            address3: model.address3,
            country: model.country,
            city: model.city,
            state: model.state,
            provinceOrRegion: model.provinceOrRegion,
            postalCode: model.postalCode,
          },
          ssn: model.ssn ?? null,
          passportCountry: model.passportCountry ?? null,
          passportNumber: model.passportNumber ?? null,
        } as SaveBeneficialOwnerRequest,
        true,
      );
    },
    async deleteBeneficialOwner(id: number): Promise<boolean> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        await $snapApi({
          method: 'DELETE',
          url: `sign-up/beneficial-owners/${id}`,
        });
      } catch {
        return false;
      } finally {
        this.loading = false;
      }

      return true;
    },
    async fetchBeneficialOwnerDocumentInfo(id: number): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: `sign-up/beneficial-owners/${id}/document-info`,
        });

        const data: BeneficialOwnerDocumentInfoResponse = response.data;

        this.beneficialOwnersInfo.beneficialOwnerDocumentInfo = {
          types: data.types,
          errorMessages: data.errorMessages,
        } as BeneficialOwnerDocumentInfoData;
      } finally {
        this.loading = false;
      }
    },
    async uploadBeneficialOwnerDocument(id: number, type: DocumentType, files: File[]): Promise<boolean> {
      const body = new FormData();

      body.append('type', type);

      for (const file of files) {
        body.append('files', file);
      }

      return await this.post(`sign-up/beneficial-owners/${id}/documents`, body);
    },
    async completeBeneficialOwnersInfo(): Promise<boolean> {
      return await this.post('sign-up/beneficial-owners-info/complete', {
        isHerebyCertifiedNoBO: this.beneficialOwnersInfo.isHerebyCertifiedNoBO,
      } as CompleteBeneficialOwnersInfoRequest);
    },
    async fetchPaperCheckInfo(): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: 'sign-up/paper-check-info',
        });

        const data: PaperCheckInfoDto = response.data;

        this.paperCheckInfo = {
          address1: data.address?.address1,
          address2: data.address?.address2,
          address3: data.address?.address3,
          country: data.address?.country,
          city: data.address?.city,
          state: data.address?.state,
          provinceOrRegion: data.address?.provinceOrRegion,
          postalCode: data.address?.postalCode,
          phone: data.phone,
          isSigned: data.isSigned,
          isAddressForChecksSameAsBusiness: data.isAddressForChecksSameAsBusiness,
          isFundingSourceExists: data.isFundingSourceExists,
          isFundingSourceAccountAndRoutingNumbersAvailable: data.isFundingSourceAccountAndRoutingNumbersAvailable,
          bankAddressAndLocation: data.bankAddress,
          companyLogoFileBase64: data.companyLogoFileName,
          printSettings: {
            printCompanyName: data.printCompanyName,
            printCompanyAddress: data.printCompanyAddress,
            printBankAddress: data.printBankAddress,
          },
        } as PaperCheckInfoData;
      } finally {
        this.loading = false;
      }
    },
    retrievePaperCheckAddressCheck(): boolean {
      return this.paperCheckInfo.isAddressForChecksSameAsBusiness ?? false;
    },
    async savePaperCheckAddressCheck(): Promise<boolean> {
      return await this.post('sign-up/paper-check-info', {
        isAddressForChecksSameAsBusiness: this.paperCheckInfo.isAddressForChecksSameAsBusiness,
      } as PaperCheckInfoRequest);
    },
    retrievePaperCheckBusinessAddress(): AddressData | null {
      const address1: string | null = this.paperCheckInfo.address1 ?? null;
      const address2: string | null = this.paperCheckInfo.address2 ?? null;
      const address3: string | null = this.paperCheckInfo.address3 ?? null;

      return address1 ? { address1, address2, address3 } : null;
    },
    retrievePaperCheckBusinessLocation(): LocationData | null {
      const country: Country | null = this.paperCheckInfo.country ?? null;
      const city: string | null = this.paperCheckInfo.city ?? null;
      const state: UsState | null = this.paperCheckInfo.state ?? null;
      const provinceOrRegion: string | null = this.paperCheckInfo.provinceOrRegion ?? null;
      const postalCode: string | null = this.paperCheckInfo.postalCode ?? null;

      return city && postalCode ? { country, city, state, provinceOrRegion, postalCode } : null;
    },
    async savePaperCheckBusinessAddressAndLocation(): Promise<boolean> {
      return await this.post('sign-up/paper-check-info', {
        address: {
          address1: this.paperCheckInfo.address1,
          address2: this.paperCheckInfo.address2,
          address3: this.paperCheckInfo.address3,
          country: this.paperCheckInfo.country,
          city: this.paperCheckInfo.city,
          state: this.paperCheckInfo.state,
          provinceOrRegion: this.paperCheckInfo.provinceOrRegion,
          postalCode: this.paperCheckInfo.postalCode,
        },
      } as PaperCheckInfoRequest);
    },
    retrievePaperCheckEin(): string | null {
      return this.paperCheckInfo.ein ?? null;
    },
    retrievePaperCheckBankInfoRequired(): boolean {
      return !(this.paperCheckInfo.isFundingSourceAccountAndRoutingNumbersAvailable ?? false);
    },
    async completeBankInfo(): Promise<boolean> {
      return await this.post('sign-up/bank-info/complete', {});
    },
    retrievePaperCheckBankAccountAndRoutingNumbers(): PaperCheckBankNumbersInfo | null {
      const accountNumber: string | null = this.paperCheckInfo.bankNumbers?.accountNumber ?? null;
      const routingNumber: string | null = this.paperCheckInfo.bankNumbers?.routingNumber ?? null;

      return accountNumber && routingNumber ? { accountNumber, routingNumber } : null;
    },
    retrievePaperCheckBankAddress(): AddressData | null {
      const address1: string | null = this.paperCheckInfo.bankAddressAndLocation?.address1 ?? null;
      const address2: string | null = this.paperCheckInfo.bankAddressAndLocation?.address2 ?? null;
      const address3: string | null = this.paperCheckInfo.bankAddressAndLocation?.address3 ?? null;

      return address1 ? { address1, address2, address3 } : null;
    },
    retrievePaperCheckBankLocation(): LocationData | null {
      const country: Country | null = this.paperCheckInfo.bankAddressAndLocation?.country ?? null;
      const city: string | null = this.paperCheckInfo.bankAddressAndLocation?.city ?? null;
      const state: UsState | null = this.paperCheckInfo.bankAddressAndLocation?.state ?? null;
      const provinceOrRegion: string | null = this.paperCheckInfo.bankAddressAndLocation?.provinceOrRegion ?? null;
      const postalCode: string | null = this.paperCheckInfo.bankAddressAndLocation?.postalCode ?? null;

      return city && postalCode ? { country, city, state, provinceOrRegion, postalCode } : null;
    },
    async savePaperCheckBankAddressAndLocation(): Promise<boolean> {
      return await this.post('sign-up/paper-check-info', {
        bankAddress: {
          address1: this.paperCheckInfo.bankAddressAndLocation?.address1,
          address2: this.paperCheckInfo.bankAddressAndLocation?.address2,
          address3: this.paperCheckInfo.bankAddressAndLocation?.address3,
          country: this.paperCheckInfo.bankAddressAndLocation?.country,
          city: this.paperCheckInfo.bankAddressAndLocation?.city,
          state: this.paperCheckInfo.bankAddressAndLocation?.state,
          provinceOrRegion: this.paperCheckInfo.bankAddressAndLocation?.provinceOrRegion,
          postalCode: this.paperCheckInfo.bankAddressAndLocation?.postalCode,
        },
      } as PaperCheckInfoRequest);
    },
    retrievePaperCheckPrintSettings(): PaperCheckPrintSettingsInfo | null {
      const printCompanyName: boolean | null = this.paperCheckInfo.printSettings?.printCompanyName ?? null;
      const printCompanyAddress: boolean | null = this.paperCheckInfo.printSettings?.printCompanyAddress ?? null;
      const printBankAddress: boolean | null = this.paperCheckInfo.printSettings?.printBankAddress ?? null;

      return printCompanyName !== null && printCompanyAddress !== null && printBankAddress !== null
        ? ({
            printCompanyName,
            printCompanyAddress,
            printBankAddress,
          } as PaperCheckPrintSettingsInfo)
        : null;
    },
    async savePaperCheckPrintSettings(): Promise<boolean> {
      return await this.post('sign-up/paper-check-info', {
        printCompanyName: this.paperCheckInfo.printSettings?.printCompanyName,
        printCompanyAddress: this.paperCheckInfo.printSettings?.printCompanyAddress,
        printBankAddress: this.paperCheckInfo.printSettings?.printBankAddress,
      } as PaperCheckInfoRequest);
    },
    retrievePaperCheckPhone(): string | null {
      return this.paperCheckInfo.phone ?? null;
    },
    async savePaperCheckPhone(): Promise<boolean> {
      return await this.post('sign-up/paper-check-info', {
        phone: this.paperCheckInfo.phone,
      } as PaperCheckInfoRequest);
    },
    retrievePaperCheckCompanyLogo(): string | null {
      return this.paperCheckInfo.companyLogoFileBase64 ?? null;
    },
    async completePaperCheckInfo(): Promise<boolean> {
      return await this.post('sign-up/paper-check-info/complete', {
        ein: this.paperCheckInfo.ein,
        signature: this.paperCheckInfo.signature,
        companyLogo: this.paperCheckInfo.companyLogoFileBase64,
        deliverySettings: this.paperCheckInfo.deliverySettings,
      } as CompletePaperCheckInfoRequest);
    },
    isBusinessIdentityExists(): boolean {
      return (!!this.personalInfo.ssnLastFour || !!this.personalInfo.ssnFull) && !!this.personalInfo.ein;
    },
    async fetchDocumentRequirements(): Promise<void> {
      if (!this.documentInfo.currentRequirement) {
        const documentRequirements = extractDocumentRequirements(this.requirements);

        this.documentInfo.currentRequirement = documentRequirements[0];
      }

      if (!this.documentInfo.currentRequirement) {
        return;
      }

      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: `sign-up/documents/requirements?requirement=${this.documentInfo.currentRequirement}`,
        });

        const data: DocumentInfoResponse = response.data as DocumentInfoResponse;

        this.documentInfo = {
          ...this.documentInfo,
          types: data.types.map(
            (t: DocumentTypeInfoResponse): DocumentTypeInfo => ({
              type: t.type,
              fileExtensions: t.fileExtensions,
            }),
          ),
          errorMessages: data.errorMessages,
        } as DocumentInfoData;
      } finally {
        this.loading = false;
      }
    },
    retrieveDocumentTypes(): DocumentTypeInfo[] {
      return this.documentInfo.types ?? [];
    },
    retrieveDocumentErrorMessages(): string[] {
      return this.documentInfo.errorMessages ?? [];
    },
    async uploadDocuments(type: DocumentType, files: File[]): Promise<boolean> {
      const { $toastError } = useNuxtApp();

      if (!this.documentInfo.currentRequirement) {
        $toastError('Invalid document requirement');

        return false;
      }

      const body = new FormData();

      body.append('requirement', this.documentInfo.currentRequirement);
      body.append('type', type);

      for (const file of files) {
        body.append('files', file);
      }

      return await this.post('sign-up/documents', body);
    },
    retrievePersonalIdentitySsnFull(): string | null {
      return this.identityInformation.ssnFull ?? null;
    },
    async completePersonalIdentityInfo(): Promise<boolean> {
      return await this.post('sign-up/personal/identity-info', {
        ssnFull: this.identityInformation.ssnFull,
      } as PersonalIdentityInfoRequest);
    },
    async validateNewAccountEmail(): Promise<boolean> {
      return await this.post('sign-up/validate-email', {
        email: this.accountInformation.email,
      } as ValidateEmailRequest);
    },
    async fetchInfoForRetryPersonal(): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: `sign-up/retry/personal`,
        });

        const data = response.data as InfoForRetryPersonalResponse;

        this.infoForRetryPersonal = {
          firstName: data.firstName,
          lastName: data.lastName,
          dob: new Date(data.dob),
          address1: data.address.address1,
          address2: data.address.address2,
          address3: data.address.address3,
          country: data.address.country,
          city: data.address.city,
          state: data.address.state,
          provinceOrRegion: data.address.provinceOrRegion,
          postalCode: data.address.postalCode,
        } as InfoForRetryPersonal;
      } finally {
        this.loading = false;
      }
    },
    retrieveInfoForRetryPersonalFullName(): ExtendedFullNameInfo | null {
      const firstName: string | null = this.infoForRetryPersonal.firstName ?? null;
      const lastName: string | null = this.infoForRetryPersonal.lastName ?? null;

      return firstName && lastName ? { firstName, lastName, title: null } : null;
    },
    retrieveInfoForRetryPersonalDoB(): Date | null {
      return this.infoForRetryPersonal.dob ?? null;
    },
    retrieveInfoForRetryPersonalAddress(): AddressData | null {
      const address1: string | null = this.infoForRetryPersonal.address1 ?? null;
      const address2: string | null = this.infoForRetryPersonal.address2 ?? null;
      const address3: string | null = this.infoForRetryPersonal.address3 ?? null;

      return address1 ? { address1, address2, address3 } : null;
    },
    retrieveInfoForRetryPersonalLocation(): LocationData | null {
      const country: Country | null = this.infoForRetryPersonal.country ?? null;
      const city: string | null = this.infoForRetryPersonal.city ?? null;
      const state: UsState | null = this.infoForRetryPersonal.state ?? null;
      const provinceOrRegion: string | null = this.infoForRetryPersonal.provinceOrRegion ?? null;
      const postalCode: string | null = this.infoForRetryPersonal.postalCode ?? null;

      return city && postalCode ? { country, city, state, provinceOrRegion, postalCode } : null;
    },
    retrieveInfoForRetryPersonalSSN(): string | null {
      return this.infoForRetryPersonal.ssn ?? null;
    },
    async saveInfoForRetryPersonal(): Promise<boolean> {
      return await this.post('sign-up/retry/personal', {
        firstName: this.infoForRetryPersonal.firstName,
        lastName: this.infoForRetryPersonal.lastName,
        dob: this.infoForRetryPersonal.dob,
        address: {
          address1: this.infoForRetryPersonal.address1,
          address2: this.infoForRetryPersonal.address2,
          address3: this.infoForRetryPersonal.address3,
          country: this.infoForRetryPersonal.country,
          city: this.infoForRetryPersonal.city,
          state: this.infoForRetryPersonal.state,
          provinceOrRegion: this.infoForRetryPersonal.provinceOrRegion,
          postalCode: this.infoForRetryPersonal.postalCode,
        },
      } as SaveInfoForRetryPersonalRequest);
    },
    async completeRetryPersonal(): Promise<boolean> {
      return await this.post('sign-up/retry/personal/complete', {
        ssn: this.infoForRetryPersonal.ssn,
      } as CompleteRetryPersonalRequest);
    },
    async fetchInfoForRetryBusinessSoleProprietorship(): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: `sign-up/retry/business-sole-proprietorship`,
        });

        const data = response.data as InfoForRetryBusinessSoleProprietorshipResponse;

        this.infoForRetryBusinessSoleProprietorship = {
          firstName: data.firstName,
          lastName: data.lastName,
          dob: new Date(data.dob),
          address1: data.address.address1,
          address2: data.address.address2,
          address3: data.address.address3,
          country: data.address.country,
          city: data.address.city,
          state: data.address.state,
          provinceOrRegion: data.address.provinceOrRegion,
          postalCode: data.address.postalCode,
          businessName: data.businessName,
          dba: data.dba,
          type: data.businessTypeId,
          classification: data.businessClassificationId,
        } as InfoForRetryBusinessSoleProprietorship;
      } finally {
        this.loading = false;
      }
    },
    retrieveInfoForRetryBusinessSoleProprietorshipFullName(): ExtendedFullNameInfo | null {
      const firstName: string | null = this.infoForRetryBusinessSoleProprietorship.firstName ?? null;
      const lastName: string | null = this.infoForRetryBusinessSoleProprietorship.lastName ?? null;

      return firstName && lastName ? { firstName, lastName, title: null } : null;
    },
    retrieveInfoForRetryBusinessSoleProprietorshipDoB(): Date | null {
      return this.infoForRetryBusinessSoleProprietorship.dob ?? null;
    },
    retrieveInfoForRetryBusinessSoleProprietorshipAddress(): AddressData | null {
      const address1: string | null = this.infoForRetryBusinessSoleProprietorship.address1 ?? null;
      const address2: string | null = this.infoForRetryBusinessSoleProprietorship.address2 ?? null;
      const address3: string | null = this.infoForRetryBusinessSoleProprietorship.address3 ?? null;

      return address1 ? { address1, address2, address3 } : null;
    },
    retrieveInfoForRetryBusinessSoleProprietorshipLocation(): LocationData | null {
      const country: Country | null = this.infoForRetryBusinessSoleProprietorship.country ?? null;
      const city: string | null = this.infoForRetryBusinessSoleProprietorship.city ?? null;
      const state: UsState | null = this.infoForRetryBusinessSoleProprietorship.state ?? null;
      const provinceOrRegion: string | null = this.infoForRetryBusinessSoleProprietorship.provinceOrRegion ?? null;
      const postalCode: string | null = this.infoForRetryBusinessSoleProprietorship.postalCode ?? null;

      return city && postalCode ? { country, city, state, provinceOrRegion, postalCode } : null;
    },
    retrieveInfoForRetryBusinessSoleProprietorshipBusinessName(): string | null {
      return this.infoForRetryBusinessSoleProprietorship.businessName ?? null;
    },
    retrieveInfoForRetryBusinessSoleProprietorshipDBA(): string | null {
      return this.infoForRetryBusinessSoleProprietorship.dba ?? null;
    },
    retrieveInfoForRetryBusinessSoleProprietorshipType(): BusinessTypes | null {
      return this.infoForRetryBusinessSoleProprietorship.type ?? null;
    },
    retrieveInfoForRetryBusinessSoleProprietorshipIndustry(): number | null {
      return this.infoForRetryBusinessSoleProprietorship.classification ?? null;
    },
    retrieveInfoForRetryBusinessSoleProprietorshipSSN(): string | null {
      return this.infoForRetryBusinessSoleProprietorship.ssn ?? null;
    },
    retrieveInfoForRetryBusinessSoleProprietorshipEIN(): string | null {
      return this.infoForRetryBusinessSoleProprietorship.ein ?? null;
    },
    async saveInfoForRetryBusinessSoleProprietorship(): Promise<boolean> {
      return await this.post('sign-up/retry/business-sole-proprietorship', {
        firstName: this.infoForRetryBusinessSoleProprietorship.firstName,
        lastName: this.infoForRetryBusinessSoleProprietorship.lastName,
        dob: this.infoForRetryBusinessSoleProprietorship.dob,
        address: {
          address1: this.infoForRetryBusinessSoleProprietorship.address1,
          address2: this.infoForRetryBusinessSoleProprietorship.address2,
          address3: this.infoForRetryBusinessSoleProprietorship.address3,
          country: this.infoForRetryBusinessSoleProprietorship.country,
          city: this.infoForRetryBusinessSoleProprietorship.city,
          state: this.infoForRetryBusinessSoleProprietorship.state,
          provinceOrRegion: this.infoForRetryBusinessSoleProprietorship.provinceOrRegion,
          postalCode: this.infoForRetryBusinessSoleProprietorship.postalCode,
        },
        businessName: this.infoForRetryBusinessSoleProprietorship.businessName,
        dba: this.infoForRetryBusinessSoleProprietorship.dba,
        businessTypeId: this.infoForRetryBusinessSoleProprietorship.type,
        businessClassificationId: this.infoForRetryBusinessSoleProprietorship.classification,
      } as SaveInfoForRetryBusinessSoleProprietorshipRequest);
    },
    async completeRetryBusinessSoleProprietorship(): Promise<boolean> {
      return await this.post('sign-up/retry/business-sole-proprietorship/complete', {
        ssn: this.infoForRetryBusinessSoleProprietorship.ssn,
        ein: this.infoForRetryBusinessSoleProprietorship.ein,
      } as CompleteRetryBusinessSoleProprietorshipRequest);
    },
    async fetchInfoForRetryBusiness(): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: `sign-up/retry/business`,
        });

        const data = response.data as InfoForRetryBusinessResponse;

        this.infoForRetryBusiness = {
          firstName: data.firstName,
          lastName: data.lastName,
          dob: new Date(data.dob),
          address1: data.address.address1,
          address2: data.address.address2,
          address3: data.address.address3,
          country: data.address.country,
          city: data.address.city,
          state: data.address.state,
          provinceOrRegion: data.address.provinceOrRegion,
          postalCode: data.address.postalCode,
          businessName: data.businessName,
          dba: data.dba,
          type: data.businessTypeId,
          classification: data.businessClassificationId,
          controller: {
            title: data.controller.title,
            firstName: data.controller.firstName,
            lastName: data.controller.lastName,
            dob: new Date(data.controller.dob),
            address1: data.controller.address.address1,
            address2: data.controller.address.address2,
            address3: data.controller.address.address3,
            country: data.controller.address.country,
            city: data.controller.address.city,
            state: data.controller.address.state,
            provinceOrRegion: data.controller.address.provinceOrRegion,
            postalCode: data.controller.address.postalCode,
          },
        } as InfoForRetryBusiness;
      } finally {
        this.loading = false;
      }
    },
    retrieveInfoForRetryBusinessFullName(): ExtendedFullNameInfo | null {
      const firstName: string | null = this.infoForRetryBusiness.firstName ?? null;
      const lastName: string | null = this.infoForRetryBusiness.lastName ?? null;

      return firstName && lastName ? { firstName, lastName, title: null } : null;
    },
    retrieveInfoForRetryBusinessDoB(): Date | null {
      return this.infoForRetryBusiness.dob ?? null;
    },
    retrieveInfoForRetryBusinessAddress(): AddressData | null {
      const address1: string | null = this.infoForRetryBusiness.address1 ?? null;
      const address2: string | null = this.infoForRetryBusiness.address2 ?? null;
      const address3: string | null = this.infoForRetryBusiness.address3 ?? null;

      return address1 ? { address1, address2, address3 } : null;
    },
    retrieveInfoForRetryBusinessLocation(): LocationData | null {
      const country: Country | null = this.infoForRetryBusiness.country ?? null;
      const city: string | null = this.infoForRetryBusiness.city ?? null;
      const state: UsState | null = this.infoForRetryBusiness.state ?? null;
      const provinceOrRegion: string | null = this.infoForRetryBusiness.provinceOrRegion ?? null;
      const postalCode: string | null = this.infoForRetryBusiness.postalCode ?? null;

      return city && postalCode ? { country, city, state, provinceOrRegion, postalCode } : null;
    },
    retrieveInfoForRetryBusinessBusinessName(): string | null {
      return this.infoForRetryBusiness.businessName ?? null;
    },
    retrieveInfoForRetryBusinessDBA(): string | null {
      return this.infoForRetryBusiness.dba ?? null;
    },
    retrieveInfoForRetryBusinessType(): BusinessTypes | null {
      return this.infoForRetryBusiness.type ?? null;
    },
    retrieveInfoForRetryBusinessClassification(): number | null {
      return this.infoForRetryBusiness.classification ?? null;
    },
    retrieveInfoForRetryBusinessControllerFullName(): ControllerFullNameInfo | null {
      const title: string | null = this.infoForRetryBusiness.controller?.title ?? null;
      const firstName: string | null = this.infoForRetryBusiness.controller?.firstName ?? null;
      const lastName: string | null = this.infoForRetryBusiness.controller?.lastName ?? null;

      return title || firstName || lastName ? { title, firstName, lastName } : null;
    },
    retrieveInfoForRetryBusinessControllerDoB(): Date | null {
      return this.infoForRetryBusiness.controller?.dob ?? null;
    },
    retrieveInfoForRetryBusinessControllerAddress(): AddressData | null {
      const address1: string | null = this.infoForRetryBusiness.controller?.address1 ?? null;
      const address2: string | null = this.infoForRetryBusiness.controller?.address2 ?? null;
      const address3: string | null = this.infoForRetryBusiness.controller?.address3 ?? null;

      return address1 ? { address1, address2, address3 } : null;
    },
    retrieveInfoForRetryBusinessControllerLocation(): LocationData | null {
      const country: Country | null = this.infoForRetryBusiness.controller?.country ?? null;
      const city: string | null = this.infoForRetryBusiness.controller?.city ?? null;
      const state: UsState | null = this.infoForRetryBusiness.controller?.state ?? null;
      const provinceOrRegion: string | null = this.infoForRetryBusiness.controller?.provinceOrRegion ?? null;
      const postalCode: string | null = this.infoForRetryBusiness.controller?.postalCode ?? null;

      return city && postalCode ? { country, city, state, provinceOrRegion, postalCode } : null;
    },
    retrieveInfoForRetryBusinessSSN(): string | null {
      return this.infoForRetryBusiness.ssn ?? null;
    },
    retrieveInfoForRetryBusinessEIN(): string | null {
      return this.infoForRetryBusiness.ein ?? null;
    },
    retrieveInfoForRetryBusinessControllerSSN(): string | null {
      return this.infoForRetryBusiness.controller?.ssn ?? null;
    },
    retrieveInfoForRetryBusinessControllerPassportNumber(): string | null {
      return this.infoForRetryBusiness.controller?.passportNumber ?? null;
    },
    retrieveInfoForRetryBusinessControllerPassportCountry(): Country | null {
      return this.infoForRetryBusiness.controller?.passportCountry ?? null;
    },
    async saveInfoForRetryBusiness(): Promise<boolean> {
      return await this.post('sign-up/retry/business', {
        firstName: this.infoForRetryBusiness.firstName,
        lastName: this.infoForRetryBusiness.lastName,
        dob: this.infoForRetryBusiness.dob,
        address: {
          address1: this.infoForRetryBusiness.address1,
          address2: this.infoForRetryBusiness.address2,
          address3: this.infoForRetryBusiness.address3,
          country: this.infoForRetryBusiness.country,
          city: this.infoForRetryBusiness.city,
          state: this.infoForRetryBusiness.state,
          provinceOrRegion: this.infoForRetryBusiness.provinceOrRegion,
          postalCode: this.infoForRetryBusiness.postalCode,
        },
        businessName: this.infoForRetryBusiness.businessName,
        dba: this.infoForRetryBusiness.dba,
        businessTypeId: this.infoForRetryBusiness.type,
        businessClassificationId: this.infoForRetryBusiness.classification,
        controller: this.infoForRetryBusiness.controller
          ? {
              firstName: this.infoForRetryBusiness.controller.firstName,
              lastName: this.infoForRetryBusiness.controller.lastName,
              title: this.infoForRetryBusiness.controller.title,
              dob: this.infoForRetryBusiness.controller.dob,
              address: {
                address1: this.infoForRetryBusiness.controller.address1,
                address2: this.infoForRetryBusiness.controller.address2,
                address3: this.infoForRetryBusiness.controller.address3,
                country: this.infoForRetryBusiness.controller.country,
                city: this.infoForRetryBusiness.controller.city,
                state: this.infoForRetryBusiness.controller.state,
                provinceOrRegion: this.infoForRetryBusiness.controller.provinceOrRegion,
                postalCode: this.infoForRetryBusiness.controller.postalCode,
              },
            }
          : undefined,
      } as SaveInfoForRetryBusinessRequest);
    },
    async completeRetryBusiness(): Promise<boolean> {
      return await this.post('sign-up/retry/business/complete-business', {
        businessSsn: this.infoForRetryBusiness.ssn,
        businessEin: this.infoForRetryBusiness.ein,
      } as CompleteRetryBusinessRequest);
    },
    async completeRetryBusinessAndController(): Promise<boolean> {
      return await this.post('sign-up/retry/business/complete-business-and-controller', {
        businessSsn: this.infoForRetryBusiness.ssn,
        businessEin: this.infoForRetryBusiness.ein,
        controllerSsn: this.infoForRetryBusiness.controller?.ssn ?? null,
        controllerPassportNumber: this.infoForRetryBusiness.controller?.passportNumber ?? null,
        controllerPassportCountry: this.infoForRetryBusiness.controller?.passportCountry ?? null,
      } as CompleteRetryBusinessAndControllerRequest);
    },
    async postAskUsAnything(value: AskUsAnythingDto): Promise<boolean> {
      return await this.post('sign-up/ask-us', value);
    },
    async fetchPersonalAddressAndLocation(): Promise<boolean> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const { data } = await $snapApi({ method: 'GET', url: 'sign-up/address-and-location/personal' });
        this.personalInfo = { ...this.personalInfo, ...data };
      } catch {
        return false;
      } finally {
        this.loading = false;
      }

      return true;
    },
    async readOnlyGoToNextStep() {
      const activePoint = this.sidebarNavigationPoints[this.sidebarCurrentPoint];
      const nextPoint = this.sidebarNavigationPoints[this.sidebarCurrentPoint + 1];
      const path: string = nextPoint?.url ?? PATH_SIGN_UP;

      this.sidebarPointsStatusMap[activePoint.url] = NavigationStatus.Finish;
      this.sidebarPointsStatusMap[path] = NavigationStatus.Process;
      this.sidebarCurrentPoint += 1;

      await navigateTo({ path });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSignUpStore, import.meta.hot));
}
